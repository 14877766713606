import * as React from "react";
import { DetailsWindow } from "./DetailsWindow";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "../CSS/slide-pane.css";
import { Label, DefaultButton, Icon } from "office-ui-fabric-react";
import Img from "react-image";
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface IProps {
	closeCallback: any;
	marker: any;
}

interface IState {
	hideDialog: boolean;
}

export class SlidingPaneInfo extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			hideDialog: true,
		};
	}

	public render() {
		let color: string = "";
		let icon: IconName = "coffee";

		switch (this.props.marker.airQuality.title) {
			case "Buna": {
				icon = "smile";
				color = "green";
				break;
			}
			case "Medie": {
				icon = "meh";
				color = "orange";
				break;
			}
			case "Rea": {
				icon = "frown";
				color = "red";
				break;
			}
		}

		return (
			<div>
				<DetailsWindow
					hideDialog={this.state.hideDialog}
					marker={this.props.marker}
					closeCallback={this._closeDialog}
				/>
				<SlidingPane
					onAfterOpen={() => {
						let margin = window.innerHeight - 534;
						document
							.getElementsByClassName("slide-pane_from_bottom")[0]
							.setAttribute("style", `margin-top: ${margin}px`);
					}}
					isOpen={true}
					from="bottom"
					width="100%"
					onRequestClose={this.props.closeCallback}
				>
					<Icon
						style={{ float: "right" }}
						iconName="ChromeClose"
						onClick={this.props.closeCallback}
					/>
					<div className={"separated"}>{this.props.marker.title}</div>

					<div className="row" style={{ marginBottom: 10 }}>
						<div className="col-sm-12 col-md-8 col-lg-8">
							<div className="row" style={{ marginBottom: 40 }}>
								<div className={"col-sm-6 col-sm-6 col-lg-6 brdRight"}>
									<div style={{ display: "", fontSize: "50px", margin: "5px" }}>
										<Label
											className={"colTitle"}
											style={{ marginBottom: "15px" }}
										>
											Calitatea Aerului
										</Label>
										<FontAwesomeIcon
											icon={["far", icon]}
											color={color}
											size="lg"
										/>
										<Label
											className={"colValue"}
											style={{ fontSize: "20px", color: color }}
										>
											{this.props.marker.airQuality.title}
										</Label>
									</div>
								</div>
								<div className={"col-sm-6 col-sm-6 col-lg-6"}>
									<div style={{ display: "", fontSize: "30px", margin: "5px" }}>
										<Label className={"colTitle"}>Vremea</Label>
										<div className="row" style={{ margin: "10px" }}>
											<FontAwesomeIcon
												color="crimson"
												icon={"thermometer-half"}
											/>

											<Label className={"colValue"}>
												{this.props.marker.temperature + "°C"}
											</Label>
										</div>
										<div className="row" style={{ margin: "10px" }}>
											<FontAwesomeIcon color="lightskyblue" icon={"tint"} />

											<Label className={"colValue"}>
												{this.props.marker.humidity + "%"}
											</Label>
										</div>
										<div className="row" style={{ margin: "10px" }}>
											<FontAwesomeIcon color="LightSlateGrey" icon={"wind"} />

											<Label className={"colValue"}>
												{this.props.marker.pressure + "hPa"}
											</Label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row" style={{ marginBottom: 10 }}>
						<div className="col">
							<Img
								//style={{ height: 140 }}
								className="thumb"
								src={[
									this.props.marker.featuredImage,
									require("../../assets/notfound.png"),
								]}
								loader={<CircularProgress />}
							/>
						</div>
					</div>
					{/* <div
						style={{
							marginBottom: 10,
							borderRadius: 5,
							border: "1px solid",
							textAlign: "center",
						}}
					>
						<Label>Statie instalata cu ajutorul</Label>
						<Label>Byteunit</Label>
					</div> */}
					<div className="row">
						<div className="col">
							<DefaultButton text={"Vezi Detalii"} onClick={this._openDialog} />
						</div>
					</div>
				</SlidingPane>
			</div>
		);
	}

	private _openDialog = () => {
		this.setState({
			hideDialog: false,
		});
	};

	private _closeDialog = () => {
		this.setState({
			hideDialog: true,
		});
	};
}
