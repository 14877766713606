import * as React from "react";
import { compose, withProps } from "recompose";
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	KmlLayer,
} from "react-google-maps";
import { ClusterMarkersComponent } from "./ClusterMarkersComponent";
import { ClusterKML } from "./ClusterKML";
import { Utils } from "../Utils/Utils";
import { AddPinSlidingPane } from "./InfoWindowComponents/AddPinSlidingPane";
import { Pin } from "../assets/models/Pin";
import { AddPinDialog } from "./InfoWindowComponents/AddPinDialog";
import "./CSS/map.css";

const silverStyle = require("../assets/silverStyle.json");

interface IProps {
	markers: any[];
	zoom: number;
	lat: number;
	lng: number;
	kmlUrl: string;

	refreshComponent: any;
}
interface IState {
	markers: any[];

	visible: boolean;
	zoom: number;
	lat: number;
	lng: number;
	height: number;
	kmlUrl: string;
}

let clasa: any;
let _map: any;

export class Map extends React.Component<IProps, IState> {
	private _markerCluster: any;
	private _markerKML: any;
	private _addRef: any;

	constructor(props: IProps) {
		super(props);

		clasa = this;

		this.state = {
			markers: props.markers,
			visible: true,
			zoom: this.props.zoom,
			lat: this.props.lat,
			lng: this.props.lng,
			height: window.innerHeight,
			kmlUrl: this.props.kmlUrl,
		};
	}

	public componentDidMount() {
		let object = document.getElementById("header");
		let headerHeight: number = 0;

		if (object != null) {
			headerHeight = object.offsetHeight;
		}

		this.setState({ height: window.innerHeight - headerHeight }, () => {
			this.forceUpdate();
		});
	}

	public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
		if (
			this.state.height != nextState.height &&
			this.state.lat != 0 &&
			this.state.lng != 0 &&
			this.state.zoom != 0
		) {
			return true;
		}

		return false;
	}

	public componentWillReceiveProps(props: IProps) {
		this.setState({ markers: props.markers });

		if (this._markerCluster != undefined) {
			this._markerCluster.setState({ markers: props.markers });
		}
		if (this._markerKML != undefined) {
			this._markerKML.setState({ markers: props.markers });
		}

		// if (
		//   this.state.zoom == 0 ||
		//   this.state.lat == 0 ||
		//   this.state.lng == 0 ||
		//   this.state.kmlUrl == ""
		// ) {
		//   console.log("In props", props.zoom, props.lat, props.lng, props.kmlUrl);
		//   console.log("In props map", _map);

		//   this.setState({
		//     markers: props.markers,
		//     zoom: props.zoom,
		//     lat: props.lat,
		//     lng: props.lng,
		//     kmlUrl: props.kmlUrl
		//   });

		//   if (_map != null) {
		//     _map.forceUpdate();
		//   }
		// }
	}

	public render() {
		console.log(
			"Folosite",
			this.state.zoom,
			this.state.lat,
			this.state.lng,
			this.state.kmlUrl
		);

		const GoogleMapExample = compose(
			withProps({
				googleMapURL:
					"https://maps.googleapis.com/maps/api/js?key=AIzaSyDWM9_q_-F_3dWiZRhIPRmXH-1lGERjy0M&v=3.exp&libraries=geometry,drawing,places&language=ro&region=RO",
				loadingElement: <div style={{ height: `100%` }} />,
				containerElement: (
					<div id="map" style={{ height: this.state.height }} />
				),
				mapElement: <div style={{ height: `100%` }} />,
			}),
			withScriptjs,
			withGoogleMap
		)(() => (
			<GoogleMap
				defaultOptions={{ styles: silverStyle }}
				onClick={(e) => {
					if (Utils.getAddMode()) {
						let lat = e.latLng.lat();
						let lng = e.latLng.lng();
						console.log(lat, lng);

						if (this._addRef != null) {
							this._addRef._lat = lat;
							this._addRef._lng = lng;
							this._addRef._name = "";
							this._addRef._deviceId = "";
							this._addRef._files = null;
							//this._addRef._category = "";
							//his._addRef._deviceType = "";
							this._addRef.setState({ isOpen: Utils.getIsMobile() });

							Utils.closeToast();
						}
					}
				}}
				ref={(map) => {
					_map = map;
					var latlngList: any[] = [];
					var bounds = new window.google.maps.LatLngBounds();

					this.props.markers.forEach((marker) => {
						if (marker.location.lat != null && marker.location.lng != null) {
							latlngList.push(
								new window.google.maps.LatLng(
									marker.location.lat,
									marker.location.lng
								)
							);
						}
					});

					latlngList.forEach((n) => {
						bounds.extend(n);
					});

					if (map != null) {
						map.fitBounds(bounds);
					}
				}}
				//defaultZoom={this.state.zoom}
				//defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
			>
				<ClusterMarkersComponent
					ref={(marker: any) => (this._markerCluster = marker)}
					markers={this.state.markers}
				/>
				<ClusterKML
					ref={(marker: any) => (this._markerKML = marker)}
					markers={this.state.markers}
				/>
				{this.state.kmlUrl != null && (
					<KmlLayer
						url={this.state.kmlUrl}
						options={{ preserveViewport: true }}
					/>
				)}
			</GoogleMap>
		));

		return (
			<div>
				{Utils.getIsMobile() ? (
					<AddPinSlidingPane
						ref={(input: any) => (this._addRef = input)}
						addMarker={this._addMarker}
					/>
				) : (
					<AddPinDialog
						ref={(input: any) => (this._addRef = input)}
						addMarker={this._addMarker}
					/>
				)}
				<GoogleMapExample />
			</div>
		);
	}

	private _addMarker = () => {
		//window.location.reload();

		Utils.setAddMode(false);
		this.props.refreshComponent();

		// let markers = this.state.markers;
		// markers.push(pin);
		// this.setState({ markers });
		// this.forceUpdate();
	};
}

window.addEventListener("resize", function (event) {
	let object = document.getElementById("header");
	let height: number = 0;

	if (object != null) {
		height = object.offsetHeight;
	}

	if (_map != null) {
		clasa.setState({
			height: window.innerHeight - height,
			zoom: _map.getZoom(),
			lat: _map.getCenter().lat(),
			long: _map.getCenter().lng(),
		});
	}
});
