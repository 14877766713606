import * as React from "react";
import { InfoWindow } from "react-google-maps";
import { Label } from "office-ui-fabric-react/lib/Label";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { DetailsWindow } from "./DetailsWindow";
import "../CSS/info-window.css";
import Img from "react-image";
import { Loader } from "../LoaderComponent";
import { CircularProgress } from "@material-ui/core";
import { MainComponent } from "../MainComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface IProps {
	closeCallback: any;
	marker: any;
}

interface IState {
	hideDialog: boolean;
}

export class InfoWindowCustom extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			hideDialog: true,
		};
	}

	public render() {
		let color: string = "";
		let icon: IconName = "coffee";

		switch (this.props.marker.airQuality.title) {
			case "Buna": {
				icon = "smile";
				color = "green";
				break;
			}
			case "Medie": {
				icon = "meh";
				color = "orange";
				break;
			}
			case "Rea": {
				icon = "frown";
				color = "red";
				break;
			}
		}

		return (
			<div>
				<DetailsWindow
					hideDialog={this.state.hideDialog}
					marker={this.props.marker}
					closeCallback={this._closeDialog}
				/>
				<InfoWindow onCloseClick={this._onWindowClose}>
					<div style={{ overflow: "hidden", margin: 10, minWidth: 600 }}>
						<div className={"separated"}>{this.props.marker.title}</div>

						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8">
								<div className="row" style={{ marginBottom: 40 }}>
									<div className={"col-sm-6 col-sm-6 col-lg-6 brdRight"}>
										<div
											style={{ display: "", fontSize: "50px", margin: "5px" }}
										>
											<Label
												className={"colTitle"}
												style={{ marginBottom: "15px" }}
											>
												Calitatea Aerului
											</Label>
											<div style={{ margin: "auto", width: "50%" }}>
												<FontAwesomeIcon
													style={{ fontSize: "50px" }}
													icon={["far", icon]}
													color={color}
													size="lg"
												/>
												<Label
													className={"colValue"}
													style={{ fontSize: "20px", color: color, padding: 0 }}
												>
													{this.props.marker.airQuality.title}
												</Label>
											</div>
										</div>
									</div>
									<div className={"col-sm-6 col-sm-6 col-lg-6"}>
										<div
											style={{ display: "", fontSize: "30px", margin: "5px" }}
										>
											<Label className={"colTitle"}>Vremea</Label>
											<div className="row" style={{ margin: "10px" }}>
												<div className={"col-sm-3 col-sm-3 col-lg-3"}>
													<FontAwesomeIcon
														color="crimson"
														icon={"thermometer-half"}
													/>
												</div>
												<div className={"col-sm-9 col-sm-9 col-lg-9"}>
													<Label className={"colValue"}>
														{this.props.marker.temperature + "°C"}
													</Label>
												</div>
											</div>
											<div className="row" style={{ margin: "10px" }}>
												<div className={"col-sm-3 col-sm-3 col-lg-3"}>
													<FontAwesomeIcon color="lightskyblue" icon={"tint"} />
												</div>
												<div className={"col-sm-9 col-sm-9 col-lg-9"}>
													<Label className={"colValue"}>
														{this.props.marker.humidity + "%"}
													</Label>
												</div>
											</div>
											<div className="row" style={{ margin: "10px" }}>
												<div className={"col-sm-3 col-sm-3 col-lg-3"}>
													<FontAwesomeIcon
														color="LightSlateGrey"
														icon={"wind"}
													/>
												</div>
												<div className={"col-sm-9 col-sm-9 col-lg-9"}>
													<Label className={"colValue"}>
														{this.props.marker.pressure + "hPa"}
													</Label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<DefaultButton
											text={"Vezi Detalii"}
											onClick={this._openDialog}
										/>
									</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4 col-lg-4">
								<Img
									//style={{ height: 140, objectFit: "cover", width: "100%" }}
									className="thumb"
									src={[
										this.props.marker.featuredImage,
										require("../../assets/notfound.png"),
									]}
									loader={<CircularProgress />}
								/>
								{/* <div
									className="col"
									style={{
										marginTop: "40px",
										borderRadius: 5,
										border: "1px solid",
										textAlign: "center",
									}}
								>
									<Label>Statie instalata cu ajutorul</Label>
									<Label>Byteunit</Label>
								</div> */}
							</div>
						</div>
					</div>
				</InfoWindow>
			</div>
		);
	}

	private _openDialog = () => {
		this.setState({
			hideDialog: false,
		});
	};

	private _closeDialog = () => {
		this.setState({
			hideDialog: true,
		});
	};

	private _onWindowClose = () => {
		this.props.closeCallback();
	};
}
