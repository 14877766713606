import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import { Button, InputLabel } from "@material-ui/core";
import { Utils } from "../Utils/Utils";
import { Dialog, DialogType, ResponsiveMode } from "office-ui-fabric-react";

interface IProps {
	hideDialog: boolean;
	closeCallback: any;
}

interface IState {
	hideDialog: boolean;
}

export class Authentication extends React.Component<IProps, IState> {
	private _clientId: string = "";
	private _clientSecret: string = "";

	constructor(props: IProps) {
		super(props);

		this.state = {
			hideDialog: true,
		};
	}

	public componentWillReceiveProps(props: IProps) {
		this.setState({
			hideDialog: props.hideDialog,
		});
	}

	public render() {
		return (
			<div style={{ borderRadius: 5 }}>
				<Dialog
					hidden={this.state.hideDialog}
					onDismiss={this._onClose}
					responsiveMode={ResponsiveMode.small}
					minWidth={200}
					dialogContentProps={{
						type: DialogType.normal,
						title: "Autentificare",
					}}
				>
					<Card style={{ padding: "40px" }}>
						<div>
							<InputLabel
								style={{
									fontFamily: "Arial, sans-serif",
									fontWeight: "bold",
								}}
							>
								Login
							</InputLabel>
						</div>
						<div>
							<TextField
								required
								label="Email"
								onChange={(e) => {
									this._clientId = e.target.value;
								}}
							/>
						</div>
						<div>
							<TextField
								required
								label="Parola"
								type="password"
								onChange={(e) => {
									this._clientSecret = e.target.value;
								}}
							/>
						</div>

						<div>
							<Button
								variant="contained"
								style={{ marginTop: "30px" }}
								onClick={() => {
									let data = {
										email: this._clientId,
										password: this._clientSecret,
									};

									fetch(Utils.getURL() + "Login/HelpLogin", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									}).then((res) => {
										return res
											.json()
											.then((body) => {
												Utils.setToken(body);
												Utils.setAddMode(true);
												this._onClose();
												Utils.showToast("Selectați locatia senzorului");
											})
											.catch((e) => {
												console.log(e);
												Utils.showToast("Credentiale gresite");
											});
									});
								}}
							>
								Login
							</Button>
						</div>
					</Card>
				</Dialog>
			</div>
		);
	}

	private _onClose = () => {
		this.setState({ hideDialog: true });
		this.props.closeCallback();
	};
}
