export class Utils {
	private static isMobile: boolean;
	private static addMode: boolean = false;
	private static userId: number = 0;
	private static token: string = "";
	//private static URL: string = "https://localhost:44301/api/";
	// private static URL: string = "https://mapsbackend.azurewebsites.net/api/";
	private static URL: string = "https://cityairbackend.azurewebsites.net/api/";
	private static showButton: boolean = false;

	public static setShowButton = (value: boolean) => {
		Utils.showButton = value;
	};

	public static getShowButton = (): boolean => {
		return Utils.showButton;
	};

	public static setToken = (value: string) => {
		Utils.token = value;
	};

	public static getToken = (): string => {
		return Utils.token;
	};

	public static setUserId = (value: number) => {
		Utils.userId = value;
	};

	public static getUserId = (): number => {
		return Utils.userId;
	};

	public static setIsMobile = (value: boolean) => {
		Utils.isMobile = value;
	};

	public static getIsMobile = (): boolean => {
		return Utils.isMobile;
	};

	public static getURL = (): string => {
		return Utils.URL;
	};

	public static setAddMode = (value: boolean) => {
		Utils.addMode = value;
	};

	public static getAddMode = (): boolean => {
		return Utils.addMode;
	};

	public static showToast = (content: string): void => {
		let snackbar = document.getElementById("snackbar");
		if (snackbar != null) {
			snackbar.className = "show";
			console.log(content);
			snackbar.innerHTML = content;
			setTimeout(function () {
				if (snackbar != null) {
					snackbar.className = snackbar.className.replace("show", "");
				}
			}, 10000);
		}
	};

	public static closeToast = () => {
		let snackbar = document.getElementById("snackbar");
		if (snackbar != null) {
			snackbar.className = snackbar.className.replace("show", "");
		}
	};
}
