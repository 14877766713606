import * as React from "react";
import { DetailsWindow } from "./DetailsWindow";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "../CSS/slide-pane.css";
import { Label, DefaultButton, Icon } from "office-ui-fabric-react";
import Img from "react-image";
import {
	CircularProgress,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { Pin } from "../../assets/models/Pin";
import { Utils } from "../../Utils/Utils";
import { pink } from "@material-ui/core/colors";
import { Device } from "../../DTO/Device";
import { CategoryType } from "../../DTO/CategoryType";
import { DeviceType } from "../../DTO/DeviceType";
const request = require("request");

interface IProps {
	addMarker: any;
}

interface IState {
	isOpen: boolean;
	imageName: string;
	deviceType: string;
	category: string;
}

export class AddPinSlidingPane extends React.Component<IProps, IState> {
	private _inputOpenFileRef: any;

	private _subject: string = "";
	private _description: string = "";
	private _files: any = null;

	private _name: string = "";
	private _deviceId: string = "";

	private _lat: any;
	private _lng: any;

	private categories: CategoryType[] = [];
	private devices: DeviceType[] = [];

	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: false,
			imageName: "",
			deviceType: "",
			category: "",
		};
	}

	public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
		if (
			nextState.isOpen == true &&
			this.categories.length == 0 &&
			this.devices.length == 0
		) {
			var optionsCategory = {
				method: "GET",
				url: Utils.getURL() + "CategoryType",
				headers: {
					Authorization: "Bearer " + Utils.getToken(),
				},
			};

			var optionsDevices = {
				method: "GET",
				url: Utils.getURL() + "DeviceType",
				headers: {
					Authorization: "Bearer " + Utils.getToken(),
				},
			};

			request(optionsCategory, (error: any, response: any, body: any) => {
				if (error) throw new Error(error);

				this.categories = JSON.parse(body);
				this.setState({ category: this.categories[0].name });
			});

			request(optionsDevices, (error: any, response: any, body: any) => {
				if (error) throw new Error(error);

				this.devices = JSON.parse(body);
				this.setState({ deviceType: this.devices[0].type });
			});
		}

		return true;
	}

	public render() {
		return (
			<div>
				<SlidingPane
					onAfterOpen={() => {
						let margin = window.innerHeight - 534;
						document
							.getElementsByClassName("slide-pane_from_bottom")[0]
							.setAttribute("style", `margin-top: ${margin}px`);
					}}
					isOpen={this.state.isOpen}
					from="bottom"
					width="100%"
					onRequestClose={this._onClose}
				>
					<Icon
						style={{ float: "right" }}
						iconName="ChromeClose"
						onClick={this._onClose}
					/>
					<div>
						<h3 style={{ marginBottom: 10 }}>Adăugare senzor</h3>
						<input
							ref={(input: any) => (this._inputOpenFileRef = input)}
							onChange={(evt) => this._changeInput(evt)}
							type="file"
							multiple
							style={{ display: "none" }}
						/>
						<div style={{ width: "100%", margin: 5 }}>
							<TextField
								onChange={this._changeName}
								style={{ width: "100%" }}
								label="Nume"
								variant="outlined"
							/>
						</div>
						<div style={{ width: "100%", margin: 5 }}>
							<TextField
								onChange={this._changeDeviceId}
								style={{ width: "100%" }}
								label="Device Id"
								variant="outlined"
							/>
						</div>
						<div style={{ width: "100%", margin: 5 }}>
							{/* <TextField
								onChange={this._changeDeviceType}
								style={{ width: "100%" }}
								label="Tip Device"
								variant="outlined"
							/> */}
							<Select
								MenuProps={{ style: { zIndex: 1000000 } }}
								style={{ width: "100%" }}
								variant="outlined"
								value={this.state.deviceType}
								onChange={(event) => {
									if (event != null && event.target != null) {
										this.setState({ deviceType: event.target.value as string });
									}
								}}
							>
								{this.devices.map((d) => {
									return <MenuItem value={d.type}>{d.type}</MenuItem>;
								})}
							</Select>
						</div>
						<div style={{ width: "100%", margin: 5 }}>
							{/* <TextField
								onChange={this._changeCategory}
								style={{ width: "100%" }}
								label="Categorie"
								variant="outlined"
							/> */}
							<Select
								MenuProps={{ style: { zIndex: 1000000 } }}
								style={{ zIndex: 100, width: "100%" }}
								variant="outlined"
								value={this.state.category}
								onChange={(event) => {
									if (event != null && event.target != null) {
										this.setState({ category: event.target.value as string });
									}
								}}
							>
								{this.categories.map((c) => {
									return <MenuItem value={c.name}>{c.name}</MenuItem>;
								})}
							</Select>
						</div>
						<div style={{ width: "100%", margin: 5 }}>
							<TextField
								defaultValue={this._lat}
								onChange={this._changeLat}
								style={{ width: "100%" }}
								label="Latitudine"
								variant="outlined"
							/>
						</div>
						<div style={{ width: "100%", margin: 5, marginBottom: 10 }}>
							<TextField
								defaultValue={this._lng}
								onChange={this._changeLng}
								style={{ width: "100%" }}
								label="Longitudine"
								variant="outlined"
							/>
						</div>
						{/* <div style={{ margin: 5 }}>
							<DefaultButton
								style={{
									borderRadius: 5,
								}}
								text={"Adauga imagine"}
								onClick={() => {
									this._inputOpenFileRef.click();
								}}
							/>
							<Typography>{this.state.imageName}</Typography>
						</div> */}
						<div style={{ margin: 5 }}>
							<DefaultButton
								style={{
									borderRadius: 5,
								}}
								text={"Adauga pin"}
								onClick={() => {
									if (
										this._name != "" &&
										this._deviceId != "" &&
										this._lat != "" &&
										this._lng != ""
									) {
										// const device: Device = {
										// 	deviceId: this._deviceId,
										// 	latitude: this._lat,
										// 	longitude: this._lng,
										// 	name: this._name,
										// 	type: this.state.deviceType,
										// 	category: this.state.category,
										// };

										let device = new FormData();
										device.append("deviceId", this._deviceId);
										device.append("name", this._name);
										device.append("type", this.state.deviceType);
										device.append("category", this.state.category);
										device.append("latitude", this._lat);
										device.append("longitude", this._lng);

										if (this._files != null) {
											Array.from(this._files).forEach((file: any) => {
												device.append("files", file);
											});
										}

										fetch(Utils.getURL() + "Register/Device", {
											method: "POST",
											headers: {
												//"Content-Type": "application/json",
												Authorization: "Bearer " + Utils.getToken(),
											},
											//body: JSON.stringify(device),
											body: device,
										}).then((res) => {
											return res
												.json()
												.then((body) => {
													this._onClose();
												})
												.catch((e) => {
													console.log(e);
													Utils.showToast("Eroare, incercati din nou");
												});
										});
									} else {
										Utils.showToast(
											"Completati toate campurile pentru a adauga senzorul"
										);
									}

									// if (this._subject != "" && this._description != "") {
									// 	let pin = new FormData();
									// 	pin.append("userId", Utils.getUserId().toString());
									// 	pin.append("subject", this._subject);
									// 	pin.append("description", this._description);
									// 	pin.append("latitude", this._lat);
									// 	pin.append("longitude", this._lng);

									// 	if (this._file != null) {
									// 		pin.append("files", this._file);
									// 	}

									// 	console.log("Pin de adaugat", pin);

									// 	fetch(Utils.getURL() + "pins/add", {
									// 		method: "POST",
									// 		body: pin,
									// 	}).then((res) => {
									// 		return res.json().then((body) => {
									// 			if (body == -1) {
									// 				Utils.showToast("Eroare, incercati din nou");
									// 			} else {
									// 				console.log(body);
									// 				this.props.addMarker(body);
									// 				this._onClose();
									// 			}
									// 		});
									// 	});
									// } else {
									// 	Utils.showToast(
									// 		"Completati subiectul si descrierea pentru a adauga sesizarea"
									// 	);
									// }
								}}
							/>
						</div>
					</div>
				</SlidingPane>
			</div>
		);
	}

	private _changeInput = (evt: any) => {
		if (evt.target.files != null && evt.target.files.length > 0) {
			this._files = evt.target.files;
			let name = "";

			Array.from(this._files).forEach((file: any) => {
				name += file.name + "; ";
			});

			this.setState({ imageName: name });
		}
	};

	// private _changeSubject = (e: any) => {
	// 	this._subject = e.target.value;
	// };

	// private _changeDescription = (e: any) => {
	// 	this._description = e.target.value;
	// };

	private _changeName = (e: any) => {
		this._name = e.target.value;
	};

	private _changeDeviceId = (e: any) => {
		this._deviceId = e.target.value;
	};

	private _changeLat = (e: any) => {
		this._lat = e.target.value;
	};

	private _changeLng = (e: any) => {
		this._lng = e.target.value;
	};

	// private _changeCategory = (e: any) => {
	// 	this._category = e.target.value;
	// };

	// private _changeDeviceType = (e: any) => {
	// 	this._deviceType = e.target.value;
	// };

	private _onClose = () => {
		this._files = null;
		// this._subject = "";
		// this._description = "";
		this._name = "";
		this._deviceId = "";
		// this._category = "";
		// this._deviceType = "";
		this.setState({ isOpen: false, imageName: "" });
		this.props.addMarker();
	};
}
