import * as React from "react";
import { List } from "office-ui-fabric-react/lib/components/List/List";
import { Label } from "office-ui-fabric-react/lib/Label";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { TooltipHost, TooltipDelay } from "office-ui-fabric-react/lib/Tooltip";
import { DirectionalHint, Callout } from "office-ui-fabric-react/lib/Callout";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Utils } from "../Utils/Utils";
import {
	Menu,
	MenuItem,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./CSS/date-picker.css";
import moment from "moment";
import "moment/locale/ro";

interface IPickerData {
	startDate: any;
	startId: string;
	endDate: any;
	endId: string;
	focusedInput: any;
	isCalloutVisible: boolean;
	refElement: any;

	min: any;
	max: any;
}

interface IProps {
	changeMode: any;
	filtersData: any;

	addIntervalFilter: any;
	addListFilter: any;
	addSearchFilter: any;
	addDateFilter: any;

	openMenu: any;

	filtersNo: number;

	showAuth: any;
	markersNo: number;
}

interface IState {
	mode: number;
	intervalValue: any;
	searchValue: string;

	dateFilters: IPickerData[];

	filtersNo: number;

	anchorEl: any;
}

export class Header extends React.Component<IProps, IState> {
	private _lastUsed: any = null;

	constructor(props: IProps) {
		super(props);

		moment.locale("ro");

		let intervalValue = {};
		let _dateFilters: IPickerData[] = [];

		if (props.filtersData != undefined) {
			props.filtersData.map((filter: any, i: number) => {
				if (filter.values == null) {
					intervalValue = {
						...intervalValue,
						[filter.property]: {
							min: Number(filter.min),
							max: Number(filter.max),
						},
					};
				}

				if (filter.type == "date") {
					console.log("Filtre aici", filter.min, moment(filter.min));

					_dateFilters[filter.property] = {
						startDate: null,
						startId: "start" + i,
						endDate: null,
						endId: "end" + i,
						focusedInput: "startDate",
						isCalloutVisible: false,
						refElement: React.createRef<HTMLDivElement>(),
						min: moment(filter.min),
						max: moment(filter.max),
					};
				}
			});
		}

		console.log("Filtru de date", _dateFilters);

		this.state = {
			mode: 1,
			intervalValue: intervalValue,
			dateFilters: _dateFilters,
			searchValue: "",
			filtersNo: props.filtersNo,
			anchorEl: null,
		};
	}

	public componentWillReceiveProps(props: IProps) {
		this.setState({
			filtersNo: props.filtersNo,
		});
	}

	public render() {
		let text: string = "";
		if (!Utils.getIsMobile()) {
			if (this.state.mode == 1) {
				text = "Lista";
			} else {
				text = "Harta";
			}
		}

		return (
			<div
				id="header"
				style={{
					padding: 20,
					backgroundColor: "#00508F",
				}}
			>
				<div className="row" style={{ margin: 0 }}>
					<div className="col-sm-6 col-md-6 col-lg-6">
						<Select
							style={{
								width: 150,
								paddingLeft: 15,
								float: "left",
								marginRight: 5,
								borderRadius: 5,
								backgroundColor: "white",
							}}
							value={this.state.mode}
							onChange={(event) => {
								if (event != null && event.target != null) {
									let value = event.target.value;
									this._changeMode(value as number);
								}
							}}
						>
							<MenuItem value={1}>Harta</MenuItem>
							<MenuItem value={2}>Lista</MenuItem>
							{/* <MenuItem value={3}>Harta Vant</MenuItem> */}
						</Select>
						<SearchBox
							value={this.state.searchValue}
							style={{ borderRadius: 5 }}
							placeholder="Cauta"
							onSearch={(newValue) => {
								this.props.addSearchFilter(newValue);

								this.setState({ searchValue: newValue });
							}}
						/>
					</div>
					<div className="col-sm-3 col-md-3 col-lg-3">
						<div className="row">
							{/* <div
								style={{
									float: "left",
									width: "60%",
									display: Utils.getIsMobile() ? "" : "inline-block",
								}}
								className="col-xs-2 col-sm-2 col-md-2 col-lg-12"
							>
								{this.state.mode != 3 && this._filters()}
							</div> */}
							<div
								style={{
									float: "right",
									width: "40%",
									display:
										Utils.getIsMobile() && this.state.mode != 3 ? "" : "none",
								}}
								className="col-xs-2 col-sm-2 col-md-2"
							>
								<DefaultButton
									style={{
										borderRadius: 5,
										backgroundColor: this.props.filtersNo > 0 ? "#CD5C5C" : "",
										color: this.props.filtersNo > 0 ? "white" : "",
									}}
									text={"Legenda"}
									onClick={this.props.openMenu}
								/>
							</div>

							{this.state.mode == 1 && Utils.getShowButton() && (
								<div>
									<DefaultButton
										style={{
											borderRadius: 5,
											marginLeft: 15,
										}}
										text={"Adaugă senzor"}
										onClick={() => {
											if (Utils.getToken() != "") {
												Utils.setAddMode(true);
												Utils.showToast("Selectați locatia senzorului");
											} else {
												Utils.showToast("Autentificați-vă pentru a continua");
												this.props.showAuth();
											}
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-3 col-md-3 col-lg-3">
						<div className="row">
							<Label
								style={{
									marginRight: "10px",
									color: "white",
								}}
							>
								{"Numarul statiilor: " + this.props.markersNo}
							</Label>
							<Label
								style={{
									color: "white",
								}}
							>
								{"Numarul institutiilor: " +
									Math.floor(this.props.markersNo / 2)}
							</Label>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private _changeMode = (mode: number) => {
		this.setState({ mode: mode });

		this.props.changeMode(mode);
	};

	private _filters = () => {
		if (Utils.getIsMobile()) {
			return (
				<ExpansionPanel>
					<ExpansionPanelSummary
						style={{ height: 32, minHeight: 0 }}
						expandIcon={<ExpandMoreIcon />}
					>
						Filtre
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<div style={{ display: "" }}>
							{this.props.filtersData.map((filter: any) => {
								return this._filterButton(filter);
							})}
						</div>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			);
		} else {
			return (
				<div style={{ marginTop: "-5px" }} /*className="col-sm-4 col-md-6" */>
					{this.props.filtersData != undefined
						? this.props.filtersData.map((filter: any) => {
								return this._filterButton(filter);
						  })
						: ""}
				</div>
			);
		}
	};

	private _filterButton = (filter: any) => {
		switch (filter.type) {
			case "intervalInteger": {
				return (
					<div
						style={{
							display: Utils.getIsMobile() ? "" : "inline-block",
							marginRight: 10,
						}}
					>
						<TooltipHost
							tooltipProps={{
								onRenderContent: () => {
									return (
										<div style={{ padding: 20, width: 200 }}>
											<InputRange
												maxValue={Number(filter.max)}
												minValue={Number(filter.min)}
												value={this.state.intervalValue[filter.property]}
												onChange={(value) => {
													let intervalValue = this.state.intervalValue;
													intervalValue[filter.property] = value;
													this.setState({ intervalValue: intervalValue });

													this._lastUsed = filter;
												}}
											/>
										</div>
									);
								},
							}}
							onTooltipToggle={(isTooltipVisible: boolean) => {
								if (!isTooltipVisible && this._lastUsed != null) {
									this.props.addIntervalFilter(
										this._lastUsed.name,
										this._lastUsed.property,
										this.state.intervalValue[this._lastUsed.property]
									);
									this._lastUsed = null;
								}
							}}
							delay={TooltipDelay.zero}
							directionalHint={DirectionalHint.bottomCenter}
							styles={{
								root: { display: "inline-block" },
							}}
						>
							<DefaultButton
								iconProps={{
									iconName: "ChevronDown",
								}}
								text={filter.name}
								style={{
									borderRadius: 5,
									width: 115,
								}}
							/>
						</TooltipHost>
					</div>
				);
			}
			case "list": {
				let items: any[] = [];

				for (let value of filter.options) {
					items.push({
						label: filter.name,
						name: value.key,
						property: filter.property,
					});
				}

				if (Utils.getIsMobile()) {
					return (
						<>
							<DefaultButton
								onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
									this.setState({
										anchorEl: event.currentTarget,
									});
								}}
								iconProps={{
									iconName: "ChevronDown",
								}}
								text={filter.name}
								style={{
									borderRadius: 5,
									width: 115,
								}}
							/>
							<Menu
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={() => {
									this.setState({
										anchorEl: null,
									});
								}}
							>
								{items.map((item) => {
									return (
										<MenuItem
											onClick={() => {
												this.props.addListFilter(
													item.label,
													item.property,
													item.name
												);
											}}
										>
											{item.name}
										</MenuItem>
									);
								})}
							</Menu>
						</>
					);
				} else {
					return (
						<div
							//className="col-xs-12 col-sm-12 col-md-3"
							style={{
								display: Utils.getIsMobile() ? "" : "inline-block",
								marginRight: 10,
							}}
						>
							<TooltipHost
								tooltipProps={{
									onRenderContent: () => {
										return (
											<List
												style={{ paddingBottom: 15 }}
												items={items}
												onRenderCell={this._onRenderCell}
											/>
										);
									},
								}}
								delay={TooltipDelay.zero}
								directionalHint={DirectionalHint.bottomCenter}
								styles={{ root: { display: "inline-block" } }}
							>
								<DefaultButton
									iconProps={{
										iconName: "ChevronDown",
									}}
									text={filter.name}
									style={{
										borderRadius: 5,
										width: 115,
									}}
								/>
							</TooltipHost>
						</div>
					);
				}
			}
			case "date": {
				return (
					<div
						ref={this.state.dateFilters[filter.property].refElement}
						style={{
							display: Utils.getIsMobile() ? "" : "inline-block",
							marginRight: 10,
						}}
					>
						{this.state.dateFilters[filter.property].isCalloutVisible && (
							<Callout
								gapSpace={0}
								target={
									this.state.dateFilters[filter.property].refElement.current
								}
								role="alertdialog"
								onDismiss={() => {
									this._onDismissDatePicker(filter);
								}}
							>
								<div
									style={{
										padding: 20,
										width: Utils.getIsMobile() ? 350 : 650,
										height: 450,
									}}
								>
									<DateRangePicker
										minDate={this.state.dateFilters[filter.property].min}
										maxDate={this.state.dateFilters[filter.property].max}
										small={Utils.getIsMobile()}
										keepOpenOnDateSelect={true}
										isOutsideRange={() => {
											return false;
										}}
										orientation={
											Utils.getIsMobile() ? "vertical" : "horizontal"
										}
										startDate={
											this.state.dateFilters[filter.property].startDate
										}
										startDateId={
											this.state.dateFilters[filter.property].startId
										}
										endDate={this.state.dateFilters[filter.property].endDate}
										endDateId={this.state.dateFilters[filter.property].endId}
										onDatesChange={({ startDate, endDate }) => {
											let dateFilters = this.state.dateFilters;

											dateFilters[filter.property].startDate = startDate;
											dateFilters[filter.property].endDate = endDate;

											this.setState({ dateFilters });
										}}
										focusedInput={
											this.state.dateFilters[filter.property].focusedInput
										}
										onFocusChange={(focusedInput) => {
											var dateFilters = this.state.dateFilters;

											dateFilters[filter.property].focusedInput = focusedInput;

											this.setState({ dateFilters });
										}}
									/>

									<DefaultButton
										text={"Salveaza"}
										style={{
											borderRadius: 5,
											width: 100,
											float: "right",
										}}
										onClick={() => {
											this._onDismissDatePicker(filter);
										}}
									/>
								</div>
							</Callout>
						)}

						<DefaultButton
							iconProps={{
								iconName: "ChevronDown",
							}}
							text={filter.name}
							style={{
								borderRadius: 5,
								width: 115,
							}}
							onClick={() => {
								let dateFilters = this.state.dateFilters;
								dateFilters[filter.property].isCalloutVisible = true;

								this.setState({ dateFilters });
							}}
						/>
					</div>
				);
			}
		}
	};

	private _onDismissDatePicker = (filter: any) => {
		let dateFilters = this.state.dateFilters;
		dateFilters[filter.property].isCalloutVisible = false;

		if (
			dateFilters[filter.property].startDate != null &&
			dateFilters[filter.property].endDate != null
		) {
			let compareFormat = "YYYY-MM-DD";
			let showFormat = "DD.MM.YYYY";

			let dates = {
				start: moment(dateFilters[filter.property].startDate).format(
					compareFormat
				),
				startShow: moment(dateFilters[filter.property].startDate).format(
					showFormat
				),
				end: moment(dateFilters[filter.property].endDate).format(compareFormat),
				endShow: moment(dateFilters[filter.property].endDate).format(
					showFormat
				),
			};

			this.props.addDateFilter(filter.name, filter.property, dates);
		}
		this.setState({ dateFilters });
	};

	private _onRenderCell = (
		item: any,
		index: number | undefined
	): JSX.Element => {
		return (
			<div
				style={{
					marginLeft: 10,
					marginRight: 10,
					flexGrow: 1,
					borderBottom: "1px solid",
				}}
			>
				<Label
					style={{ cursor: "pointer" }}
					onClick={() => {
						this.props.addListFilter(item.label, item.property, item.name);
					}}
				>
					{item.name}
				</Label>
			</div>
		);
	};
}
