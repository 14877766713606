import * as React from "react";
import {
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { Utils } from "../Utils/Utils";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Label, DefaultButton } from "office-ui-fabric-react";
// @ts-ignore
import Workbook from "react-xlsx-workbook";
import {
	PDFDownloadLink,
	Document,
	Page,
	Text,
	View,
} from "@react-pdf/renderer";
import { Card } from "@material-ui/core";
import "./CSS/ListComponent.css";

interface IProps {
	markers: any[];
	filters: any[];
}

interface IState {
	columns: IColumn[];
	items: IListItem[];
}

interface IListItem {
	icon: string;
	title: string;

	airQuality: string;
	humidity: number;
	temperature: number;
	pressure: number;
	location: string;
}

export class ListComponent extends React.Component<IProps, IState> {
	private _columns: IColumn[] = [];
	private _printData: any[] = [];

	constructor(props: IProps) {
		super(props);

		if (Utils.getIsMobile()) {
			this._columns.push({
				key: "column1",
				name: "Nume",
				minWidth: 100,
				maxWidth: window.innerWidth - 20,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return (
						<ExpansionPanel>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography
									style={{
										overflow: "hidden",
										whiteSpace: "normal",
									}}
								>
									{item.title + " (" + item.location + ")"}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<div style={{ display: "" }}>
									<Label
										className={"colTitle"}
										style={{ margin: 0, padding: 0 }}
									>
										Calitatea Aerului
									</Label>
									<Label
										className={"colValue"}
										style={{ margin: 0, padding: 0 }}
									>
										{item.airQuality}
									</Label>
									<Label
										className={"colTitle"}
										style={{ margin: 0, padding: 0 }}
									>
										Temperatura
									</Label>
									<Label
										className={"colValue"}
										style={{ margin: 0, padding: 0 }}
									>
										{item.temperature}
									</Label>
									<Label
										className={"colTitle"}
										style={{ margin: 0, padding: 0 }}
									>
										Presiune
									</Label>
									<Label
										className={"colValue"}
										style={{ margin: 0, padding: 0 }}
									>
										{item.pressure}
									</Label>
									<Label
										className={"colTitle"}
										style={{ margin: 0, padding: 0 }}
									>
										Umiditate
									</Label>
									<Label
										className={"colValue"}
										style={{ margin: 0, padding: 0 }}
									>
										{item.humidity}
									</Label>
								</div>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					);
				},
			});
		} else {
			// this._columns.push({
			// 	key: "column1",
			// 	name: "File Type",
			// 	iconName: "Page",
			// 	minWidth: 16,
			// 	maxWidth: 16,
			// 	onColumnClick: this._onColumnClick,
			// 	onRender: (item: IListItem) => {
			// 		return <img style={{ width: 25, height: 25 }} src={item.icon} />;
			// 	},
			// });
			this._columns.push({
				key: "column2",
				name: "Nume",
				minWidth: 20,
				maxWidth: 300,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.title}</div>;
				},
			});
			this._columns.push({
				key: "column3",
				name: "Locatie",
				minWidth: 20,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.location}</div>;
				},
			});
			this._columns.push({
				key: "column4",
				name: "Calitatea Aerului",
				minWidth: 20,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.airQuality}</div>;
				},
			});
			this._columns.push({
				key: "column5",
				name: "Temperatura",
				minWidth: 20,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.temperature}</div>;
				},
			});
			this._columns.push({
				key: "column6",
				name: "Presiune",
				minWidth: 20,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.pressure}</div>;
				},
			});
			this._columns.push({
				key: "column7",
				name: "Umiditate",
				minWidth: 20,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				onRender: (item: IListItem) => {
					return <div className="wordwrap ">{item.humidity}</div>;
				},
			});
		}

		let _allItems: IListItem[] = [];

		for (let i = 0; i < props.markers.length; i++) {
			let printObj: any = {
				Name: props.markers[i].title,
				AirQuality:
					props.markers[i].airQuality != null
						? props.markers[i].airQuality.title
						: null,
				Temperature: props.markers[i].temperature,
				Humidity: props.markers[i].humidity,
				Pressure: props.markers[i].pressure,
				Location: props.markers[i].position,
			};

			_allItems.push({
				title: props.markers[i].title,
				icon: props.markers[i].iconURL,
				airQuality:
					props.markers[i].airQuality != null
						? props.markers[i].airQuality.title
						: null,
				humidity: props.markers[i].humidity,
				temperature: props.markers[i].temperature,
				pressure: props.markers[i].pressure,
				location: props.markers[i].position,
			});

			this._printData.push(printObj);
		}

		this.state = {
			items: _allItems,
			columns: this._columns,
		};
	}

	public componentWillReceiveProps(props: IProps) {
		let _allItems: IListItem[] = [];
		for (let i = 0; i < props.markers.length; i++) {
			_allItems.push({
				title: props.markers[i].title,
				icon: props.markers[i].iconURL,
				airQuality:
					props.markers[i].airQuality != null
						? props.markers[i].airQuality.title
						: null,
				humidity: props.markers[i].humidity,
				temperature: props.markers[i].temperature,
				pressure: props.markers[i].pressure,
				location: props.markers[i].position,
			});
		}

		this.setState({
			items: _allItems,
		});
	}

	public render() {
		const { columns, items } = this.state;

		let columnsXLS = null;
		if (this._printData.length > 0) {
			columnsXLS = Object.keys(this._printData[0]).map((key) => {
				let labelName = "";
				switch (key) {
					case "Name": {
						labelName = "Nume";
						break;
					}
					case "Location": {
						labelName = "Locatie";
						break;
					}
					case "AirQuality": {
						labelName = "Calitatea Aerului";
						break;
					}
					case "Temperature": {
						labelName = "Temperatura";
						break;
					}
					case "Humidity": {
						labelName = "Umiditate";
						break;
					}
					case "Pressure": {
						labelName = "Presiune";
						break;
					}
				}
				return <Workbook.Column label={labelName} value={key} />;
			});
		}

		if (Utils.getIsMobile()) {
			return (
				<>
					<div
						style={{
							marginTop: 20,
							marginBottom: 5,
							textAlign: Utils.getIsMobile() ? "center" : "left",
						}}
					>
						<Workbook
							filename="Senzori.xlsx"
							element={<DefaultButton text={"Export Excel"} />}
						>
							<Workbook.Sheet data={this._printData} name="Senzori">
								{columnsXLS}
							</Workbook.Sheet>
						</Workbook>

						{/* <PDFDownloadLink
							document={this._generatePDF()}
							fileName="Senzori.pdf"
						>
							{({ blob, url, loading, error }) =>
								loading ? (
									"Loading document..."
								) : (
									<DefaultButton text={"Export PDF"} />
								)
							}
						</PDFDownloadLink> */}
					</div>

					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						layoutMode={DetailsListLayoutMode.fixedColumns}
						isHeaderVisible={true}
					/>
				</>
			);
		} else {
			return (
				<Card
					style={{
						marginTop: 10,
						padding: 20,
						boxShadow: "2px 2px 2px 2px #888888",
					}}
				>
					<div
						style={{
							marginTop: 20,
							marginBottom: 5,
							textAlign: Utils.getIsMobile() ? "center" : "left",
						}}
					>
						<Workbook
							filename="Senzori.xlsx"
							element={<DefaultButton text={"Export Excel"} />}
						>
							<Workbook.Sheet data={this._printData} name="Senzori">
								{columnsXLS}
							</Workbook.Sheet>
						</Workbook>

						{/* <PDFDownloadLink
							document={this._generatePDF()}
							fileName="Senzori.pdf"
						>
							{({ blob, url, loading, error }) =>
								loading ? (
									"Loading document..."
								) : (
									<DefaultButton text={"Export PDF"} />
								)
							}
						</PDFDownloadLink> */}
					</div>

					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible={true}
					/>
				</Card>
			);
		}
	}

	private _generatePDF = () => {
		return (
			<Document>
				<Page>
					<Text style={{ fontSize: 18, margin: 20 }}>Lista investitii:</Text>
					{this._printData.map((item: any) => {
						return (
							<Text style={{ fontSize: 12, margin: "10px 20px 10px 20px" }}>
								{Object.keys(item).map((key) => {
									return key + ": " + item[key] + " / ";
								})}
							</Text>
						);
					})}
				</Page>
			</Document>
		);
	};

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = this._copyAndSort(
			items,
			currColumn.fieldName!,
			currColumn.isSortedDescending
		);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};

	private _copyAndSort<T>(
		items: T[],
		columnKey: string,
		isSortedDescending?: boolean
	): T[] {
		const key = columnKey as keyof T;
		return items
			.slice(0)
			.sort((a: T, b: T) =>
				(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
			);
	}
}
