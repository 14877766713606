import * as React from "react";
import {
	Dialog,
	DialogType,
	DialogFooter,
	ResponsiveMode,
} from "office-ui-fabric-react/lib/Dialog";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
// @ts-ignore
import Carousel from "react-images";
import { Line } from "react-chartjs-2";
import "../CSS/details-window.css";
import { Label } from "office-ui-fabric-react";
import { DeviceData } from "../../DTO/DeviceData";
import { Utils } from "../../Utils/Utils";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore
import Facebook from "react-sharingbuttons/dist/buttons/Facebook";
import "react-sharingbuttons/dist/main.css";
import { AnyARecord } from "dns";
import { MenuItem, Select} from "@material-ui/core";
import { Loader } from "../LoaderComponent";

const request = require("request");

interface IProps {
	hideDialog: boolean;
	marker: any;
	closeCallback: any;
	
}

interface IState {
	yearValue:number;
	monthValue:number;
	hideLoader:boolean;

	hideDialog: boolean;

	currentIndex: number;

	chartData: any;

	images: any;
}

interface months{
	id:number;
	value:string;
}

export class DetailsWindow extends React.Component<IProps, IState> {
	private deviceData: DeviceData[] = [];

	private yearsArray:number[] = [];
	private monthArray:months[] = [{id:1,value:"Ianuarie"},
								   {id:2,value:"Februarie"},
								   {id:3,value:"Martie"},
								   {id:4,value:"Aprilie"},
								   {id:5,value:"Mai"},
								   {id:6,value:"Iunie"},
								   {id:7,value:"Iulie"},
								   {id:8,value:"August"},
								   {id:9,value:"Septembrie"},
								   {id:10,value:"Octombrie"},
								   {id:11,value:"Noiembrie"},
								   {id:12,value:"Decembrie"}]

	private chartOptions = {
		plugins: {
			tooltip: {
				callbacks: {
					title: (tooltipItem: any) => {
						let index = tooltipItem[0].dataIndex;

						return tooltipItem[0].dataset.data[index] + " ";
					},
					label: (tooltipItem: any) => {
						console.log("Aici", tooltipItem);
						let index = tooltipItem.dataIndex;
						let label = "";

						label +=
							"Temperatura: " + this.deviceData[index].temperature + "C \n";
						label += "Umiditate: " + this.deviceData[index].humidity + "% \n";
						label += "Presiune: " + this.deviceData[index].pressure + "hPa \n";

						return label;
					},
				},
			},
		},
	};

	private _chartData = {
		labels: [""],
		datasets: [
			{
				label: "PM 10",
				data: [0],
				fill: false,
				backgroundColor: "rgb(255, 99, 132)",
				borderColor: "rgba(255, 99, 132, 0.2)",
				yAxisID: "y-axis-1",
			},
			{
				label: "PM 2.5",
				data: [0],
				fill: false,
				backgroundColor: "rgb(54, 162, 235)",
				borderColor: "rgba(54, 162, 235, 0.2)",
				yAxisID: "y-axis-1",
			},
		],
	};

	constructor(props: IProps) {
		super(props);

		let currentYear = new Date().getFullYear();
		console.log("currentYear ", currentYear)
		
		this.yearsArray = [];
		for(let i = 2021; i <= currentYear ; i++){
			this.yearsArray.push(i);
		}		
		
		let defaultYear = this.yearsArray[this.yearsArray.length-1];

		this.state = {
			hideDialog: this.props.hideDialog,
			currentIndex: 0,
			chartData: {},
			images: [],
			yearValue:defaultYear,
			monthValue:new Date().getMonth() + 1,
			hideLoader:true
		};
	}

	public componentWillReceiveProps(props: IProps) {
		this.setState({
			hideDialog: props.hideDialog,
		});

		if (!props.hideDialog) {
			var options = {
				method: "GET",
				url: Utils.getURL() + "SenzorData/" + this.props.marker.deviceId,
			};

			request(options, (error: any, response: any, body: any) => {
				if (error) throw new Error(error);

				console.log("Device Data", JSON.parse(body));
				this.deviceData = JSON.parse(body);

				if (
					this.deviceData != undefined &&
					this.deviceData != null &&
					this.deviceData.length > 0
				) {
					if (props.marker.position == "Exterior") {
						this._chartData.datasets[0].label = "PM 1";
					}
					if (props.marker.position == "Interior") {
						this._chartData.datasets[0].label = "PM 10";
					}

					this.deviceData.map((e) => {
						var date = new Date(e.createdAt);
						let dateString: string = "";
						if (date.getMinutes() < 10) {
							dateString = date.getHours() + ":0" + date.getMinutes();
						} else {
							dateString = date.getHours() + ":" + date.getMinutes();
						}
						if (e.sdS1 != null && e.sdS1 != null) {
							this._chartData.labels.push(dateString);

							this._chartData.datasets[0].data.push(parseInt(e.sdS1.value));
							this._chartData.datasets[1].data.push(parseInt(e.sdS2.value));
						}
					});

					this._chartData.labels.shift();
					this._chartData.datasets[0].data.shift();
					this._chartData.datasets[1].data.shift();

					this.setState({ chartData: this._chartData });
				}
			});

			let optionsImages = {
				method: "GET",
				url: Utils.getURL() + "Image/" + this.props.marker.deviceId,
			};

			request(optionsImages, (error: any, response: any, body: any) => {
				if (error) throw new Error(error);

				let images: string[] = JSON.parse(body);

				let processedImages: any = [];
				images.map((i) => {
					processedImages.push({ src: i });
				});

				this.setState({ images: processedImages });
			});
		}
	}

	public render() {
		let color: string = "";
		let icon: IconName = "coffee";

		switch (this.props.marker.airQuality.title) {
			case "Buna": {
				icon = "smile";
				color = "green";
				break;
			}
			case "Medie": {
				icon = "meh";
				color = "orange";
				break;
			}
			case "Rea": {
				icon = "frown";
				color = "red";
				break;
			}
		}

		return (
			<div style={{ borderRadius: 5 }}>
				<Dialog
					hidden={this.state.hideDialog}
					onDismiss={this._closeDialog}
					responsiveMode={ResponsiveMode.medium}
					minWidth={1000}
					dialogContentProps={{
						type: DialogType.normal,
						title: this.props.marker.title,
					}}
				>
					<Loader hideDialog={this.state.hideLoader} />
					<div className="row">
						<div style={{ marginLeft: "auto" }}>
							<Facebook
								text="Share"
								url={
									"https://map.cityair.ro/app/cityAir.php?text=" +
									this.props.marker.title +
									"&imageUrl=" +
									this.props.marker.featuredImage
								}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-md-12 col-lg-12">
							<div className="row" style={{ marginBottom: 40 }}>
								<div className={"col-sm-3 col-md-3 col-lg-3 brdRight"}>
									<div style={{ display: "" }}>
										<Label
											className={"colTitle"}
											style={{ margin: 0, padding: 0, fontSize: "20px" }}
										>
											Calitatea Aerului
										</Label>
										<div className="row">
											<div
												style={{
													textAlign: "center",
													margin: "auto",
													marginLeft: "15px",
													marginRight: "5px",
												}}
											>
												<FontAwesomeIcon
													icon={["far", icon]}
													color={color}
													size="lg"
												/>
											</div>
											<Label
												className={"colValue"}
												style={{ margin: 0, padding: 0 }}
											>
												{this.props.marker.airQuality.title}
											</Label>
										</div>
									</div>
								</div>
								<div className={"col-sm-3 col-md-3 col-lg-3 brdRight"}>
									<div style={{ display: "" }}>
										<Label
											className={"colTitle"}
											style={{ margin: 0, padding: 0, fontSize: "20px" }}
										>
											Temperatura
										</Label>
										<div className="row">
											<div
												style={{
													textAlign: "center",
													margin: "auto",
													marginLeft: "15px",
													marginRight: "5px",
												}}
											>
												<FontAwesomeIcon
													color="crimson"
													icon={"thermometer-half"}
												/>
											</div>
											<Label
												className={"colValue"}
												style={{ margin: 0, padding: 0 }}
											>
												{this.props.marker.temperature + "°C"}
											</Label>
										</div>
									</div>
								</div>
								<div className={"col-sm-3 col-md-3 col-lg-3 brdRight"}>
									<div style={{ display: "" }}>
										<Label
											className={"colTitle"}
											style={{ margin: 0, padding: 0, fontSize: "20px" }}
										>
											Umiditate
										</Label>
										<div className="row">
											<div
												style={{
													textAlign: "center",
													margin: "auto",
													marginLeft: "15px",
													marginRight: "5px",
												}}
											>
												<FontAwesomeIcon color="lightskyblue" icon={"tint"} />
											</div>
											<Label
												className={"colValue"}
												style={{ margin: 0, padding: 0 }}
											>
												{this.props.marker.humidity + "%"}
											</Label>
										</div>
									</div>
								</div>
								<div className={"col-sm-3 col-md-3 col-lg-3"}>
									<div style={{ display: "" }}>
										<Label
											className={"colTitle"}
											style={{ margin: 0, padding: 0, fontSize: "20px" }}
										>
											Presiune
										</Label>
										<div className="row">
											<div
												style={{
													textAlign: "center",
													margin: "auto",
													marginLeft: "15px",
													marginRight: "5px",
												}}
											>
												<FontAwesomeIcon color="LightSlateGrey" icon={"wind"} />
											</div>
											<Label
												className={"colValue"}
												style={{ margin: 0, padding: 0 }}
											>
												{this.props.marker.pressure + "hPa"}
											</Label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Line
						type="line"
						data={this.state.chartData}
						options={this.chartOptions}
					/>

					{/* <div className="row">{this._table()}</div> */}

					{this.state.images.length != 0 && (
						<div>
							<div style={{ marginBottom: 20 }}>
								<Carousel
									currentIndex={this.state.currentIndex}
									views={this.state.images}
								/>
							</div>

							{this._gallery()}
						</div>
					)}

					<div className={"separatedDetailWindow"}><span>Exportați datele senzorilor selectând anul și luna</span></div>

					<div className={"grid-container "}>
												
						<div >
							<Label		
								style={{
									padding:"0"
								}}					
							>
								An
							</Label>
							<Select
								MenuProps={{ style: { zIndex: 1000000 } }}						
								value={this.state.yearValue}
								onChange={ (event) =>{
									let value = event.target.value as number;
									this.setState({
										yearValue:value
									})
								}}
							>
								{this.yearsArray.map(option => {
								return (
									<MenuItem value={option}>
										{option}
									</MenuItem>
									)
								})}							
							</Select>
						</div>

						<div >
							<Label		
								style={{
									padding:"0"
								}}					
							>
								Lună
							</Label>
							<Select
								MenuProps={{ style: { zIndex: 1000000 } }}						
								value={this.state.monthValue}
								onChange={ (event) =>{
									let value = event.target.value as number;
									this.setState({
										monthValue:value
									})
								}}
								style = {{
									marginLeft:"-15"
								}}
							>
								{this.monthArray.map(option => {
								return (
									<MenuItem key={option.id} value={option.id}>
										{option.value}
									</MenuItem>
									)
								})}		
							</Select>
						</div>

						<div className={"grid-item"} >
							<DefaultButton 
							onClick={this._export} text="Export" />
						</div>
					</div>

					<DialogFooter>
						<DefaultButton onClick={this._closeDialog} text="Inchide" />
					</DialogFooter>
				</Dialog>
			</div>
		);
	}

	private _export = () =>{
		this.setState({
			hideLoader:false
		});
		let testUrl = "http://localhost:56668/api/senzordata/"
		let options = {} as Object;
		if(this.props.marker.position == "Interior"){
			options = {
				method: "GET",
				url: Utils.getURL() + "SenzorData/ExportNetigo/" + this.props.marker.deviceId + "/" + this.state.yearValue + "/" + this.state.monthValue,
				//  url: testUrl + "ExportNetigo/" + this.props.marker.deviceId + "/" + this.state.yearValue + "/" + this.state.monthValue,
			};
		}else{
			options = {
				method: "GET",
				url: Utils.getURL() + "SenzorData/ExportNubo/" + this.props.marker.deviceId + "/" + this.state.yearValue + "/" + this.state.monthValue,
				//  url: testUrl + "ExportNubo/" + this.props.marker.deviceId + "/" + this.state.yearValue + "/" + this.state.monthValue,
			};
		}
		
		request(options, (error: any, response: any, body: any) => {
			this.setState({
				hideLoader:true
			});
			let result = JSON.parse(body);
			if(result != null && result != undefined){
				const urlFile = `data:application/octet-stream;base64,${result.fileContents}`;

			const link = document.createElement('a');
			link.download = result.fileDownloadName;
			link.href = urlFile;
			link.click();
			}			
		});
	}

	private _filterRows = () => {
		let rowNumbers = Math.floor(
			this.props.marker.FilterValues.PinFilterValues.length / 3
		);

		let rows = [];
		for (let i = 0; i < rowNumbers; i++) {
			rows.push(i * 3);
		}

		rows.map((startIndex) => {
			return (
				<div className="row rowTable">
					<div className="col-sm-4 col-md-4 col-lg-4 brdRight">
						<div style={{ display: "" }}>
							<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
								{
									this.props.marker.FilterValues.PinFilterValues[startIndex]
										.filterName
								}
							</Label>
							<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
								{this.props.marker.FilterValues.PinFilterValues[startIndex]
									.key != undefined
									? this.props.marker.FilterValues.PinFilterValues[startIndex]
											.key
									: this.props.marker.FilterValues.PinFilterValues[startIndex]
											.value}
							</Label>
						</div>
					</div>
					<div className="col-sm-4 col-md-4 col-lg-4 brdRight">
						<div style={{ display: "" }}>
							<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
								{
									this.props.marker.FilterValues.PinFilterValues[startIndex + 1]
										.filterName
								}
							</Label>
							<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
								{this.props.marker.FilterValues.PinFilterValues[startIndex + 1]
									.key != undefined
									? this.props.marker.FilterValues.PinFilterValues[
											startIndex + 1
									  ].key
									: this.props.marker.FilterValues.PinFilterValues[
											startIndex + 1
									  ].value}
							</Label>
						</div>
					</div>
					<div className="col-sm-4 col-md-4 col-lg-4">
						<div style={{ display: "" }}>
							<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
								{
									this.props.marker.FilterValues.PinFilterValues[startIndex + 2]
										.filterName
								}
							</Label>
							<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
								{this.props.marker.FilterValues.PinFilterValues[startIndex + 2]
									.key != undefined
									? this.props.marker.FilterValues.PinFilterValues[
											startIndex + 2
									  ].key
									: this.props.marker.FilterValues.PinFilterValues[
											startIndex + 2
									  ].value}
							</Label>
						</div>
					</div>
				</div>
			);
		});

		switch (this.props.marker.FilterValues.PinFilterValues.length % 3) {
			case 0: {
				return;
			}
			case 1: {
				return (
					<div className="row rowTable">
						<div className="col-sm-12 col-md-12 col-lg-12">
							<div style={{ display: "" }}>
								<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
									{
										this.props.marker.FilterValues.PinFilterValues[
											3 * rowNumbers
										].filterName
									}
								</Label>
								<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
									{this.props.marker.FilterValues.PinFilterValues[
										3 * rowNumbers
									].key != undefined
										? this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers
										  ].key
										: this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers
										  ].value}
								</Label>
							</div>
						</div>
					</div>
				);
			}
			case 2: {
				return (
					<div className="row rowTable">
						<div className="col-sm-6 col-md-6 col-lg-6 brdRight">
							<div style={{ display: "" }}>
								<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
									{
										this.props.marker.FilterValues.PinFilterValues[
											3 * rowNumbers
										].filterName
									}
								</Label>
								<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
									{this.props.marker.FilterValues.PinFilterValues[
										3 * rowNumbers
									].key != undefined
										? this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers
										  ].key
										: this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers
										  ].value}
								</Label>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-6">
							<div style={{ display: "" }}>
								<Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
									{
										this.props.marker.FilterValues.PinFilterValues[
											3 * rowNumbers + 1
										].filterName
									}
								</Label>
								<Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
									{this.props.marker.FilterValues.PinFilterValues[
										3 * rowNumbers + 1
									].key != undefined
										? this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers + 1
										  ].key
										: this.props.marker.FilterValues.PinFilterValues[
												3 * rowNumbers + 1
										  ].value}
								</Label>
							</div>
						</div>
					</div>
				);
			}
		}
	};

	private _table = () => {
		return (
			<>
				{this.props.marker.details.metadata.map((data: any) => {
					if (data.type == "highlight | table | row") {
						return (
							<div className="col-sm-6 col-md-3 col-lg-3 table">
								<Label>{data.content.title}</Label>
								<Label>{data.content.body}</Label>
							</div>
						);
					}
				})}
			</>
		);
	};

	private _columns = () => {
		return (
			<div>
				{this.props.marker.details.metadata.map((data: any) => {
					if (data.type == "highlight | columns | row") {
						return (
							<div className="table">
								<Label>{data.content.title}</Label>
								<Label>{data.content.body}</Label>
							</div>
						);
					}
				})}
			</div>
		);
	};

	private _gallery = () => {
		return (
			<div className="wrapper">
				{this.state.images.map((image: any, index: number) => {
					return (
						<div className={"box"}>
							<img
								alt=""
								className="gallery"
								src={image.src}
								onClick={() => {
									this.setState({ currentIndex: index });
								}}
							/>
						</div>
					);
				})}
			</div>
		);
	};

	private _closeDialog = (): void => {
		this.setState({ hideDialog: true });
		this.props.closeCallback();
	};
}
