import * as React from "react";
import { KmlLayer } from "react-google-maps";

interface IProps {
  markers: any[];
}

interface IState {
  markers: any[];
}

export class ClusterKML extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      markers: props.markers
    };
  }

  public componentWillReceiveProps(props: IProps) {
    this.setState({
      markers: props.markers
    });
  }

  public render() {
    return (
      <>
        {this.state.markers.map((marker, i) => {
          return (
            <KmlLayer
              url={marker.KMLFileURL}
              options={{ preserveViewport: true }}
            />
          );
        })}
      </>
    );
  }
}
